export default [
  {
    path: '/addresses-category',
    name: 'addresses-category',
    meta: {
      requiresAuth: true,
      permission: 'address_category/craete_edit_category_address',
      title: 'Direcciones',
      module: 'addresses-category',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-map-marker',
      isBaseLayout: true
    },
    component: () =>
      import(
        /* webpackChunkName: "addresses-category" */ '@/views/addresses-category/AddressesCategoryLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'addresses-category-list',
        meta: {
          adminLayoutNav: true,
          permission: '',
          requiresAuth: true,
          layout: 'admin',
          title: 'Lista de direcciones',
          module: 'addresses-category',
          icon: 'mdi-view-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "addresses-category-list" */ '@/views/addresses-category/AddressesCategoryList.vue'
          )
      }
    ]
  }
];
