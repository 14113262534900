var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$route.meta.notBreadCrumb && !_vm.$route.meta.customBreadCrumb)?[_c('nav',{staticClass:"breadcrumbs"},_vm._l((_vm.breadcrumbs),function(crumb,index){return _c('div',{key:index},[_c('router-link',{class:{
            'breadcrumb-link': crumb.to,
            'breadcrumb-dark-theme': _vm.$vuetify.theme.isDark
          },attrs:{"to":crumb.to}},[_vm._v(" "+_vm._s(crumb.text)+" ")]),(index < _vm.breadcrumbs.length - 1)?_c('span',{staticClass:"breadcrumb-separator"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left")])],1):_vm._e()],1)}),0),_c('h1',{staticClass:"mt-n4 d-flex flex-row align-baseline"},[(_vm.$route.meta.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.$vuetify.theme.isDark ? 'white' : 'primary'}},[_vm._v(_vm._s(_vm.$route.meta.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.meta.title)+" "),(_vm.$route.meta?.socket && _vm.computedSocketValueKeyUserAuthFetched)?_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.computedSocketValueKeyUserAuth))]):_vm._e(),(
          _vm.$route.meta?.allowedTotalDocumentsForIndexPages &&
          _vm.entireState.app.totalDocumentsForIndexPagesBreadCrumbs > 0
        )?_c('span',{staticClass:"ml-1"},[(_vm.entireState.app.loadingTotalDocumentsForIndexPagesBreadCrumbs)?_c('span',{staticClass:"ml-2"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":24,"color":"primary"}})],1):_c('span',[_vm._v(" ("+_vm._s(_vm._f("numberFormatNoDecimal")(_vm.entireState.app.totalDocumentsForIndexPagesBreadCrumbs))+") ")])]):_vm._e()],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }