// state

const baseState = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Date time',
          value: 'date_transaction'
        }
      ],
      optionValue: 'unique_id',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Reference',
          value: 'reference'
        },
        {
          text: 'Trip ID',
          value: 'trip.unique_id'
        },
        {
          text: 'User ID',
          value: 'user.unique_id'
        },
        {
          text: 'Provider ID',
          value: 'provider.unique_id'
        },
        {
          text: 'Cedula',
          value: 'document'
        },
        {
          text: 'Receiving transaction ID',
          value: 'bank_transaction.unique_id'
        },
        {
          text: 'Phone',
          value: 'phone'
        }
      ],
      optionValue: ''
    },
    banksFilter: {
      value: undefined,
      options: []
    },
    statusFilter: {
      value: -1,
      options: [
        {
          text: 'Todos',
          value: -1
        },
        {
          text: 'Exitoso',
          value: 1
        },
        {
          text: 'Datos Erróneos',
          value: 2
        },
        {
          text: 'Plataforma caída',
          value: 3
        }
      ]
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 20
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, user) {
    commit('SET_FILTERS', user);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  user: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
