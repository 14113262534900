let baseState = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Descripción',
          value: 'title_es'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      optionValue: 'unique_id',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: 1
    },
    searchFilter: {
      searchValue: null,
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Nombre',
          value: 'title_es'
        }
      ],
      optionValue: null
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  user: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
