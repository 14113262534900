export default [
  {
    path: '/rcv',
    name: 'rcv',
    meta: {
      requiresAuth: true,
      permission: 'rcv/rcv_list',
      title: 'RCV',
      module: 'rcv',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-id-card',
    },
    component: () =>
      import (
        /* webpackChunkName: "rcv" */ '@/views/rcv/RcvLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'rcv-list',
        meta: {
          adminLayoutNav: true,
          permission: 'rcv/rcv_list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Lista de vehículos RCV',
          module: 'rcv',
          icon: 'mdi-table',
        },
        component: () =>
          import (
            /* webpackChunkName: "rcv-list" */ '@/views/rcv/RcvList.vue'
          ),
      },
      {
        path: 'config',
        name: 'rcv-config',
        meta: {
          adminLayoutNav: true,
          permission: 'rcv/config',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuración RCV',
          module: 'rcv',
          icon: 'mdi-cog',
        },
        component: () =>
          import (
            /* webpackChunkName: "rcv-config" */ '@/views/rcv/RcvConfig.vue'
          ),
      }
    ]
  }
];
