const { policyStatus, paymentFrequency } = require('@/views/rcv/utils');
const state = {
  filters: {
    vehicleTypes: {
      value: '',
      options: [],
      select: 0
    },
    sortFilter: {
      options: [
        {
          text: 'Placa',
          value: 'plate_no'
        },
        {
          text: 'Número póliza',
          value: 'rcv.policy_number'
        },
        {
          text: 'Estatus póliza',
          value: 'rcv.policy_status'
        }
      ],
      optionValue: 'plate_no',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: 1,
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'Placa',
          value: 'plate_no'
        },
        {
          text: 'Número póliza',
          value: 'rcv.policy_number'
        },
      ],
    },
    policyStatusFilter: {
      options: Object.keys(policyStatus).map(key => ({
        text: policyStatus[key].name,
        value: key
      })),
      optionValue: '',
    },
    paymentFrequencyFilter: {
      options: Object.keys(paymentFrequency).map(key => ({
        text: paymentFrequency[key],
        value: key
      })),
      optionValue: '',
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10,
    },
    expandTable: false,
  }
}

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
}

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  }
}

const getters = {
  filters: (state) => state.filters
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}