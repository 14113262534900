let baseState = {
  filters: {
    sortFilter: {
      options: [
        {
          text: 'Fecha de creación',
          value: 'created_at'
        },
        {
          text: 'Fecha de expiración',
          value: 'expired_at'
        },
        {
          text: 'Fecha de actualización',
          value: 'updated_at'
        }
      ],
      optionValue: 'created_at',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'Hexagon Index',
          value: 'hexagon_index'
        }
      ],
      optionValue: 'hexagon_index'
    },
    coordinates: {
      latitude: 0,
      longitude: 0
    },
    citiesFilter: {
      selectedCityId: null,
      citiesOptions: []
    },
    typeFilter: {
      selectedTypeId: null,
      typesOptions: []
    },
    textResult: {
      text: null
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  user: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
