import moment from 'moment-timezone';

/**
 * Formats a number with dot (".") as thousands separators.
 * This function converts the provided numeric value to a string,
 * adding dot separators for the thousands places without including any decimal part.
 * If the value passed is not a number, it logs an error and returns the input unchanged.
 *
 * @param {number} value - The number to be formatted.
 * @returns {string} The formatted number as a string with thousands separators.
 * If the input is not a number, the original input is returned.
 *
 * @example
//  * // returns '1.222.333'
 * numberFormatNoDecimal(1222333);
 */
const numberFormatNoDecimal = (value) => {
  if (value !== 0 && !Boolean(value)) {
    return '';
  }
  if (typeof value !== 'number') {
    console.error('The value is not a number');
    return value;
  }

  // Convert the number to a string and split it into integer and decimal parts
  const parts = value.toFixed(0).toString().split('.');

  // Add . as thousands separators to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Join the parts back together
  return parts.join('.');
};

/**
 * Convierte una hora en formato militar (24 horas) a formato estándar de 12 horas con AM/PM.
 *
 * @param {string} value - La hora en formato militar (HH:mm) que se va a convertir.
 *
 * @returns {string} - La hora convertida en formato de 12 horas con AM/PM. Devuelve una cadena vacía si no se proporciona `value`.
 *
 * @example
 * // Convierte una hora en formato militar a formato de 12 horas.
 * militaryToAmPm('14:30');
 * // Devuelve: '02:30 PM'
 *
 * @example
 * // Convierte medianoche en formato militar.
 * militaryToAmPm('00:00');
 * // Devuelve: '12:00 AM'
 *
 * @example
 * // Convierte mediodía en formato militar.
 * militaryToAmPm('12:00');
 * // Devuelve: '12:00 PM'
 */
const militaryToAmPm = (value) => {
  if (!value) return '';

  const [hour, minute] = value.split(':').map(Number);
  let period = 'AM';
  let formattedHour = hour;

  if (hour === 0) {
    formattedHour = 12;
  } else if (hour === 12) {
    period = 'PM';
  } else if (hour > 12) {
    formattedHour = hour - 12;
    period = 'PM';
  }

  formattedHour = formattedHour.toString().padStart(2, '0');
  const formattedMinute = minute.toString().padStart(2, '0');

  return `${formattedHour}:${formattedMinute} ${period}`;
};

/**
 * Convierte una fecha a una zona horaria específica y la formatea según un formato dado.
 *
 * @param {string|Date} value - La fecha que se va a convertir. Puede ser un string o un objeto Date.
 * @param {string} format - El formato de salida que se aplicará a la fecha, compatible con Moment.js.
 * @param {string} [timezone='UTC'] - La zona horaria a la que se debe convertir la fecha. El valor predeterminado es 'UTC'.
 *
 * @returns {string} - La fecha formateada en la zona horaria especificada. Si no se proporciona `value`, devuelve una cadena vacía.
 *
 * @example
 * // Convierte una fecha a la zona horaria de Caracas (UTC-4) y la formatea.
 * momentTimeZone('2024-09-16T12:00:00Z', 'YYYY-MM-DD hh:mm A', 'America/Caracas');
 * // Devuelve: '2024-09-16 08:00 AM'
 */
const momentTimeZone = (value, format, timezone = 'UTC') => {
  if (!value) return '';
  return moment(value).tz(timezone).format(format);
};

export { numberFormatNoDecimal, militaryToAmPm, momentTimeZone };
