const colors = {
  boring: '#C2C2C2',
  nice: '#3BD4AE',
  godPleaseNo: '#DE2568',
}

export const policyStatus = {
  inRenewal: {
    name: 'En renovación',
    color: colors.boring,
  },
  revoked: {
    name: 'Anulada',
    color: colors.godPleaseNo,
    textColor: 'white',
  },
  active: {
    name: 'Activa',
    color: colors.nice,
  },
  inIssuance: {
    name: 'En emisión',
    color: colors.boring,
  },
}

export const paymentFrequency = {
  monthly: 'Mensual',
  bimonthly: 'Bimensual',
  quarterly: 'Trimestral',
  fourMonthly: 'Cuatrimestral',
  semiannual: 'Semestral',
  annual: 'Anual',
}

export const receiptStatus = {
  pending: {
    name: 'Pendiente por cobrar',
    color: colors.boring,
  },
  paid: {
    name: 'Cobrado',
    color: colors.nice,
  },
  returned: {
    name: 'Devuelto',
    color: colors.godPleaseNo,
  },
  returnPending: {
    name: 'Pendiente por devolver',
    color: colors.boring,
  },
  revoked: {
    name: 'Anulado',
    color: colors.godPleaseNo,
  },
}

/**
 * Returns insurance company's name
 * @param {string} code
 * @returns {string} Name of the insurance company
 */
export function getInsuranceCompany(code) {
  switch (code) {
    case 'segurosVenezuela':
      return 'Seguros Venezuela';
    default:
      return 'N/A';
  }
}

/**
 * Get the name and color of the policy status
 * @param {} code - Policy status code
 * @returns {{ name: string, color: string }} - Object with name and color representing the policy status
 */
export function getPolicyStatus(code) {
  const status = policyStatus[code];
  if (!status) {
    return {
      name: 'N/A',
      color: colors.boring,
    }
  }
  return status;
}

/**
 * Get the name of the receipt status
 * @param {string} code - Receipt status code
 * @returns {string} - Name of the receipt status
 */
export function getReceiptStatus(code) {
  const status = receiptStatus[code];
  return status;
}

export function getPaymentFrequency(code) {
  return paymentFrequency[code] || 'N/A';
}

export function getCurrencyByCode(code) {
  switch (code) {
    case 'VED':
      return 'Bs. ';
    case 'USD':
      return '$';
    default:
      throw new Error('Invalid currency');
  }
}